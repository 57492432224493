// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/vercel/workpath0/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-page-2-js": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-atoms-checkbox-mdx": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/Atoms/Checkbox.mdx" /* webpackChunkName: "component---src-pages-atoms-checkbox-mdx" */),
  "component---src-pages-atoms-button-mdx": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/Atoms/Button.mdx" /* webpackChunkName: "component---src-pages-atoms-button-mdx" */),
  "component---src-pages-atoms-link-button-mdx": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/Atoms/LinkButton.mdx" /* webpackChunkName: "component---src-pages-atoms-link-button-mdx" */),
  "component---src-pages-atoms-radio-mdx": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/Atoms/Radio.mdx" /* webpackChunkName: "component---src-pages-atoms-radio-mdx" */),
  "component---src-pages-atoms-numerical-input-mdx": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/Atoms/NumericalInput.mdx" /* webpackChunkName: "component---src-pages-atoms-numerical-input-mdx" */),
  "component---src-pages-atoms-select-mdx": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/Atoms/Select.mdx" /* webpackChunkName: "component---src-pages-atoms-select-mdx" */),
  "component---src-pages-atoms-textarea-mdx": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/Atoms/Textarea.mdx" /* webpackChunkName: "component---src-pages-atoms-textarea-mdx" */),
  "component---src-pages-atoms-text-input-mdx": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/Atoms/TextInput.mdx" /* webpackChunkName: "component---src-pages-atoms-text-input-mdx" */),
  "component---src-pages-atoms-toggles-mdx": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/Atoms/Toggles.mdx" /* webpackChunkName: "component---src-pages-atoms-toggles-mdx" */),
  "component---src-pages-foundations-colors-mdx": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/Foundations/Colors.mdx" /* webpackChunkName: "component---src-pages-foundations-colors-mdx" */),
  "component---src-pages-foundations-icons-mdx": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/Foundations/Icons.mdx" /* webpackChunkName: "component---src-pages-foundations-icons-mdx" */),
  "component---src-pages-foundations-spacing-mdx": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/Foundations/Spacing.mdx" /* webpackChunkName: "component---src-pages-foundations-spacing-mdx" */),
  "component---src-pages-foundations-typography-mdx": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/Foundations/Typography.mdx" /* webpackChunkName: "component---src-pages-foundations-typography-mdx" */),
  "component---src-pages-guides-migration-docs-mdx": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/Guides/Migration Docs.mdx" /* webpackChunkName: "component---src-pages-guides-migration-docs-mdx" */),
  "component---src-pages-organisms-dropdown-mdx": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/Organisms/Dropdown.mdx" /* webpackChunkName: "component---src-pages-organisms-dropdown-mdx" */),
  "component---src-pages-organisms-toast-mdx": () => import("/vercel/workpath0/packages/swarm-docs/src/pages/Organisms/Toast.mdx" /* webpackChunkName: "component---src-pages-organisms-toast-mdx" */)
}


import { TogglePill, Toggle } from '@meetup/swarm-components';
import PillExample from "../../../../src/components/examples/togglePill";
import SwitchExample from "../../../../src/components/examples/toggle";
import PropsTable from "../../../../src/components/propstable";
import React from 'react';
export default {
  TogglePill,
  Toggle,
  PillExample,
  SwitchExample,
  PropsTable,
  React
};
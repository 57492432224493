module.exports = [{
      plugin: require('/vercel/workpath0/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/vercel/workpath0/packages/swarm-docs/src/components/layout-mdx.js"},"extensions":[".md",".mdx"]},
    },{
      plugin: require('/vercel/workpath0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/workpath0/packages/swarm-docs/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

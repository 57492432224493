import { Button } from '@meetup/swarm-components';
import { Icon } from '@meetup/swarm-components';
import Example from "../../../../src/components/examples/button";
import PropsTable from "../../../../src/components/propstable";
import { Close, Check } from '@meetup/swarm-icons/lib/components/solid';
import React from 'react';
export default {
  Button,
  Icon,
  Example,
  PropsTable,
  Close,
  Check,
  React
};
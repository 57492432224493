import Icons from "../../../../src/components/Icons";
import * as SolidIcons from '@meetup/swarm-icons/lib/components/solid';
import * as LineIcons from '@meetup/swarm-icons/lib/components/line';
import * as LargeIcons from '@meetup/swarm-icons/lib/components/large';
import React from 'react';
export default {
  Icons,
  SolidIcons,
  LineIcons,
  LargeIcons,
  React
};
module.exports = [
{
	name: "color-merlot",
	hex: "#7A1D47",
},

{
	name: "color-meetup-red",
	hex: "#FF1154",
},

{
	name: "color-peach",
	hex: "#F65858",
},

{
	name: "color-marigold",
	hex: "#FFAD43",
},

{
	name: "color-indigo",
	hex: "#00455D",
},

{
	name: "color-viridian",
	hex: "#008294",
},

{
	name: "color-beach",
	hex: "#97CAD1",
},

{
	name: "color-old-gold",
	hex: "#947F5F",
},

{
	name: "color-white",
	hex: "#FFFFFF",
},

{
	name: "color-alert-red",
	hex: "#C80000",
},

{
	name: "color-beach-highlight",
	hex: "#F7FEFF",
},

{
	name: "color-tooltip-blue",
	hex: "#3679E4",
},

{
	name: "color-confirm-green",
	hex: "#07b203",
},

{
	name: "color-warning-orange",
	hex: "#E5800B",
},

{
	name: "color-gray-1",
	hex: "#F6F7F8",
},

{
	name: "color-gray-2",
	hex: "#E6E6E6",
},

{
	name: "color-gray-3",
	hex: "#D9D9D9",
},

{
	name: "color-gray-4",
	hex: "#C5C4C4",
},

{
	name: "color-gray-5",
	hex: "#A2A2A2",
},

{
	name: "color-gray-6",
	hex: "#757575",
},

{
	name: "color-gray-7",
	hex: "#212121",
},
]
